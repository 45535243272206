import { createSelector } from 'reselect';
import { ShimState } from 'state-domains/types';

import { SnackbarNotification, SnackbarNotificationState } from '../types';

const snackbarNotifications = (state: Partial<ShimState>): SnackbarNotificationState => {
    const notificationList = {} as Record<string, SnackbarNotification>;
    const { snackbarNotifications = { notificationList } as SnackbarNotificationState } =
        state || {};
    return snackbarNotifications;
};

const snackbarState = (state: Partial<ShimState>): SnackbarNotificationState => {
    const { snackbarNotifications = {} as SnackbarNotificationState } = state || {};
    return snackbarNotifications;
};

const snackbarNotificationCollection = (
    state: Partial<ShimState>,
): Record<string, SnackbarNotification> => {
    const { notificationList = {} as Record<string, SnackbarNotification> } =
        snackbarNotifications(state);
    return notificationList;
};

const snackbarNotificationArray = (state: Partial<ShimState>): SnackbarNotification[] => {
    const { notificationList = {} as Record<string, SnackbarNotification> } =
        snackbarNotifications(state);
    return Object.values(notificationList);
};

const selectedRowCol = createSelector(
    snackbarState,
    ({ selectedRowCol = null }: SnackbarNotificationState) => selectedRowCol,
);

export const selectors = {
    snackbarNotifications,
    snackbarNotificationCollection,
    snackbarNotificationArray,
    selectedRowCol,
};
